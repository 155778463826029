import React, { PureComponent } from "react";
import { Layout, Row, Col, PageHeader, Tag, Card, Avatar, List, Spin, Image } from 'antd';
import filter from 'lodash.filter';
import Meta from "antd/lib/card/Meta";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import ReadMoreReact from 'read-more-react';

import { FlagOutlined, CalendarOutlined, ContainerOutlined, CaretRightOutlined } from '@ant-design/icons';

export default class EventEmbed extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      profil: null
    }
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {

    fetch(`https://8u3okfqop4.execute-api.eu-west-1.amazonaws.com/production/galaxies?id=${this.props.match.params.profil_id}`)
    .then(response => response.json() )
    .then((responseJson) => {

      this.setState({
        isLoading: false,
        profil: responseJson[0]
      })
    })
  }

  render() {

    return (
      <Layout className="galaxie-app">
        <Row>
          <Col span={24}>
            {
              ! this.state.isLoading && this.state.profil ?
                <Card>
                  <Meta
                    avatar={<Avatar shape="square" style={{borderRadius:'5px'}} size={80} src={`https://atelier.leparisien.fr/galaxie-djihadiste/photos/${this.state.profil.image != "" ? this.state.profil.image : 'avatar.jpg'}`} />}
                    title={<div>
                      <Title level={4}>{this.state.profil.name}</Title>
                      <List
                        style={{marginTop: "10px"}}
                        itemLayout="horizontal"
                        dataSource={[{
                          title: <span><b>Statut :</b> {this.state.profil.statut != "" ? this.state.profil.statut : "N.C."}</span>,
                          avatar: <CalendarOutlined />
                        }, {
                          title: <span><b>Date :</b> {this.state.profil.datedelattaque != "" ? this.state.profil.datedelattaque : "N.C."}</span>,
                          avatar: <CalendarOutlined />
                        }]}
                        renderItem={item => (
                          <List.Item style={{padding:"0 !important"}}>
                            <List.Item.Meta
                              avatar={item.avatar}
                              title={item.title}
                            />
                          </List.Item>
                        )}
                      />
                    </div>}
                  />
                  <Row style={{marginTop: "10px"}}>
                    <Col span={24}>
                      <Image width={"100%"} src={`https://atelier.leparisien.fr/galaxie-djihadiste/images/${this.state.profil.img_carte != "" ? this.state.profil.img_carte : 'avatar.jpg'}`} />
                    </Col>
                  </Row>
                  <Row style={{marginTop: "10px"}}>
                    <Col span={24}>
                      <Paragraph>{this.state.profil.texte != "" ? <ReadMoreReact readMoreText="Lire plus" text={this.state.profil.texte} /> : "N.C."}</Paragraph>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "5px"}}>
                    <Col span={24}>
                      <Title level={5}>Relation(s)</Title>
                      <div>
                      {
                        filter(this.state.profil.entries, { entry_type: 'P'}).slice(0, 7).map((event) => (
                          <a href={`/galaxie-djihadiste/embed/profil/${event.entry_id}`} target="_blank" rel="noopener noreferrer"><Avatar shape="square" style={{margin: 5}} size={64} src={`https://atelier.leparisien.fr/galaxie-djihadiste/photos/${event.entry_image != "" ? event.entry_image : 'avatar.jpg'}`} /></a>
                        ))
                      }
                      {
                        this.state.profil.entries.length > 7 && <Avatar shape="square" style={{margin: 5}} size={64}>+{this.state.profil.entries.length-7}</Avatar>
                      }
                      </div>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "10px"}}>
                    <Col span={24}>
                      <a href={`/evenement/${this.state.profil.ID}`} target="_blank" rel="noopener noreferrer"><CaretRightOutlined /> En savoir plus</a>
                    </Col>
                    <Col span={24}>
                      Dernière mise à jour : {this.state.profil.datemaj}
                    </Col>
                  </Row>
                </Card>
              :
              <Spin />
            }
          </Col>
        </Row>
      </Layout>
    )
  }
}