import React from 'react';
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Input, Row, Col, Button, List, Layout, Select, Spin, PageHeader, Card } from 'antd';
import dayjs from 'dayjs'
import { AutoComplete } from 'antd';
import types_commerce from '../type_commerce'
import { PhoneOutlined, MailOutlined, HomeOutlined, GlobalOutlined } from '@ant-design/icons';

require('dayjs/locale/fr')

const { Content } = Layout;

const { Option } = AutoComplete;

class ClickCollectListByDistance extends React.Component {
  state = { 
    distance: 3, 
    coordinates: "48.853362,2.287557",
    nextToken: 0,
    total: 0,
    isLoading: false,
    fetching: true,
    status: null,
    popupLoading: false,
    places: [],
    postal_code: '75015',
    search: [],
    auto: [],
    type_commerce: 'all',
    is_delivrable_and_collect: true,
    is_delivrable: false,
    is_clickncollect: false,
    services: 'allservice'
  };

  componentDidMount() {
    this.loadByDistance();
  }

  handleSearch = value => {
    this.setState({
      auto: [],

    }, () => {
    
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}&limit=5&lat=48.866667&lon=2.333333`)
      .then(response => response.json())
      .then((body) => {
        const data = body.features.map(res => ({
          text: `${res.properties.label}`,
          value: res.geometry.coordinates[1] + "," + res.geometry.coordinates[0],
          key: res.properties.id,
        }));
        this.setState({ auto: data, fetching: false });
      });
      
    })
  };

  loadByDistance() {

    this.setState({
      isLoading: true
    }, () => {
      var spl = this.state.coordinates.split(',')

      var coordinates = {
        lat: parseFloat(spl[0]),
        lon: parseFloat(spl[1])
      }

      var filters = {
        place: coordinates,
        km: this.state.distance,
        nextToken: this.state.nextToken,
        status: 1,
        is_delivrable_and_collect: this.state.services == 'allservice',
        is_clickncollect: this.state.services == 'retrait',
        is_delivrable: this.state.services == 'livraison',
        limit: 12
      };

      if (this.state.type_commerce !== 'all') {
        filters.type = this.state.type_commerce;
      }

      API.graphql(
        graphqlOperation(queries.searchClickCollectByDistance, filters)
      )
      .then(result => {

        this.setState({
          isLoading: false,
          places: result.data.searchClickCollectByDistance.items,
          nextToken: result.data.searchClickCollectByDistance.nextToken,
          total: result.data.searchClickCollectByDistance.total
        });
      })
      .catch(err => console.log(err));
    })
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleSelect = (value) => {
    console.log(value)
    this.setState({
      coordinates: value
    })
  }

  handleSubmit = (value) => {
    this.loadByDistance()
  }

  filterType = (value) => {
    this.setState({
      type_commerce: value
    })
  }

  filterService = (value) => {
    this.setState({
      services: value
    })
  }


  render() {
    const { auto } = this.state;
    const children = auto.map(res => <Option key={res.key} value={res.value}>{res.text}</Option>);
 
    return (
      <Layout style={{ padding: '10px', width: "100%", height: "100%" }} ref={el => (this.componentRef = el)} >
        <Content style={{
          margin: 0, minHeight: 400, width: "100%", height: "100%"
        }}
        >
          <PageHeader 
            title={"Covid-19 - Commerçants parisiens - Retrait de commande ou livraison"}
            style={{padding: '10px'}}>
            <Row>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <AutoComplete  style={{ width: "100%" }} onSearch={this.handleSearch} onSelect={this.handleSelect} placeholder="10 boulevard de Grenelle 75015 Paris">
                  {children}
                </AutoComplete>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>
            <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Select style={{ width: "100%" }} onChange={(value) => this.filterType(value)} defaultValue="all">
                  <Option value="all" key="all">Tous types de commerce</Option>
                  {
                    types_commerce.map((tc) => (
                      <Option value={tc.name} key={tc.name}>{tc.name}</Option>
                    ))
                  }
                </Select>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>
            <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Select style={{ width: "100%" }} onChange={(value) => this.filterService(value)} defaultValue="allservice">
                  <Option value="allservice" key="allservice">Tous services</Option>
                  <Option value="livraison" key="Livraisons à domicile">Livraisons à domicile</Option>
                  <Option value="retrait" key="Retrait de commandes en magasin">Retrait de commandes en magasin</Option>
                </Select>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>

            <Row style={{marginTop: '10px'}}>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Button style={{ width: "100%" }} type="primary" onClick={ () => this.handleSubmit() } >Rechercher</Button>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>
          </PageHeader>
          <Row>
            <Col className="layout-row-col" id="layout-row-8-col-1-charts" style={{padding:'0 10px 10px 10px'}} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>  
            {
              ! this.state.isLoading ?
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  locale={{
                    emptyText: 'Renseignez une adresse'
                  }}
                  itemLayout="vertical"
                  size="large"
                  dataSource={this.state.places}
                  renderItem={item => (
                    <List.Item
                      key={item.place.id}
                    >
                      <Card 
                        type="inner"
                        title={item.place.type}
                      >
                        <div>
                          <div>{item.place.name}<br/>{item.place.formatted_address}</div>
                          <div>à {(Math.round(item.distance*10)/10).toString().replace('.', ',')}km</div>
                          <div>
                          {
                            item.place.phone !== "N.C." ? <a href={`tel:${item.place.phone}`}><Button style={{ backgroundColor: "#FFFBE6", borderRadius: '5px', borderColor: "#FFFBE6", margin: '5px'}} icon={<PhoneOutlined />}>{item.place.phone}</Button></a> : ""
                          }
                          {
                            item.place.mail !== "N.C." ? <a href={`mailto:${item.place.mail}`}><Button style={{ backgroundColor: "#FFFBE6", borderRadius: '5px', borderColor: "#FFFBE6", margin: '5px'}} icon={<MailOutlined />}>Mail</Button></a> : ""
                          }
                          {
                            item.place.website_url !== "N.C." ? <a href={`${item.place.website_url}`} target="_blank" rel="noopener noreferrer"><Button style={{ backgroundColor: "#FFFBE6", borderRadius: '5px', borderColor: "#FFFBE6", margin: '5px'}} icon={<GlobalOutlined />}>Site</Button></a> : ""
                          }
                          </div>
                        </div>
                      </Card>
                    </List.Item>
                  )}
                />
              :
                <div className="planeLoader"><Spin size="large" /></div>
            }
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default ClickCollectListByDistance;
