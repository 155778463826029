import React from 'react';
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Input, Row, Col, Button, List, Layout, Select, Spin, PageHeader, Card } from 'antd';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import dayjs from 'dayjs'
import 'antd/lib/layout/style/css'
import 'antd/lib/select/style/css'
import 'antd/lib/notification/style/css'
import 'antd/lib/form/style/css'
import 'antd/lib/page-header/style/css'
import 'leaflet/dist/leaflet.css'

import topojson_france_departement from '../topojson/france-departement'

require('dayjs/locale/fr')
export const pointerIcon = new L.Icon({
  iconUrl: 'https://assets.livemixr.com/images/green-point.png',
  iconRetinaUrl: 'https://assets.livemixr.com/images/green-point.png',
  iconSize: [15, 15],
})

export const pointerRedIcon = new L.Icon({
  iconUrl: 'https://assets.livemixr.com/images/red-point.png',
  iconRetinaUrl: 'https://assets.livemixr.com/images/red-point.png',
  iconSize: [15, 15],
})

const { Content } = Layout;

const { Option } = Select;

const country_options = {}

country_options['France'] = {
  path: topojson_france_departement,
  location_title: " en France",
  projection: "geoMercator",
  projectionConfig: {
    center: [2.344683, 48.8024005],
    scale: 24000
  }
}

class ListByPostalCode extends React.Component {
  state = { 
    distance: 50, 
    coordinates: "48.8024005,2.344683",
    nextToken: 0,
    total: 0,
    isLoading: true,
    status: null,
    popupLoading: false,
    places: [],
    postal_code: '94270'
  };

  componentDidMount() {
    this.loadByPostalCode();
  }

  loadByPostalCode() {

    API.graphql(
      graphqlOperation(queries.placeByPostalCode, {
        postal_code: this.state.postal_code,
        limit: 1000
      })
    )
    .then(result => {

      this.setState({
        isLoading: false,
        places: result.data.placeByPostalCode.items,
        nextToken: result.data.placeByPostalCode.nextToken,
        total: result.data.placeByPostalCode.total
      });
    })
    .catch(err => console.log(err));
  }

  onChangeStatus = (value) => {
    this.setState({
      status: value
    }, () => this.loadSearchByDistance() )
  }

  onChange = (key, value) => {
    console.log(key, value)
    this.setState({
      [key]: value
    })
  }

  handleSubmit = (value) => {
    console.log(this.state )
    this.loadByPostalCode()
  }

  render() {
    return (
      <Layout style={{ padding: '20px', width: "100%", height: "100%" }} ref={el => (this.componentRef = el)} >
        <Content style={{
          margin: 0, minHeight: 400, width: "100%", height: "100%"
        }}
        >
          <PageHeader title={null}>
            <Input key="postal_code"  style={{width: "150px"}} onChange={evt => this.onChange('postal_code', evt.target.value)} value={this.state.postal_code} placeholder="Code Postal" />
            <Button type="primary" onClick={ () => this.handleSubmit() } >Rechercher Code Postal</Button>
          </PageHeader>
          <div id='map' style={{
            margin: 0, minHeight: 400, width: "100%", height: "100%"
          }}>
            {
              ! this.state.isLoading ?
                this.state.places.map((item) => (
                  <Col span={4} style={{padding:'10px'}}>
                    <Card title={<p>{ item.title }</p>} bordered={false}>
                      <h3>{item.location.formatted_address}</h3>
                      <h4>{ item.status == 0 ? 'Fermé' : 'Ouvert' }</h4>
                      {
                        item.ordersLV && item.ordersLV.items.length > 0 
                        ?
                          <div>
                            <p>Parisien / Aujourd'hui en France livrée dans ce kiosque</p>
                            <ul>
                              {
                                item.ordersLV.items.map((order) => (
                                  <li>{dayjs(order.date*1000).locale('fr').format('DD MMMM YYYY')}</li>
                                ))
                              }
                            </ul>
                          </div>
                          :
                          <div>
                            <p>Pas de Parisien / Aujourd'hui en France dans ce kiosque</p>
                          </div>
                      }
                    </Card>
                  </Col>
                ))
              :
                <div className="planeLoader"><Spin size="large" /></div>
            }
          </div>
        </Content>
      </Layout>
    );
  }
}

export default ListByPostalCode;
