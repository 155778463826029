import React from 'react'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import {
  Form, Input, Button, Spin
} from 'antd';
import { Layout } from 'antd';
const { Content, Footer } = Layout;

class Authenticator extends React.Component {

  state = {
    username: '',
    password: '',
    authCode: '',
    loading: false,
    errors: []
  }

  constructor(props) {
    super(props);

    const { history } = this.props;
    Auth.currentAuthenticatedUser()
    .then((user) => {
      history.push('/')
    })
    .catch((err) => console.log(err))
  }


  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  signIn = (e) => {

    e.preventDefault()

    var errors = []
    if (this.state.username == '') {
      errors.push('username_mandatory') 
    }
    
    if (this.state.password == '') {
      errors.push('password_mandatory')
    }

    if (errors.length > 0) {
      this.setState({
        errors: errors
      })
    } else {
      this.setState({
        loading: true
      }, () => {
        Auth.signIn(this.state.username, this.state.password)
          .then(user => {
            this.props.history.push('/')
          })
          .catch(err => {
            var errors = this.state.errors
            errors.push(err.code)
            console.log('error signing in...: ', err, errors)
            this.setState({
              loading: false,
              errors: errors
            })
          })
      })
    }
  }

  render() {
    return (
      <Layout className="layout">
        <Content style={{ padding: '50px 50px 0px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ background: '#fff', padding: 24, minHeight: 280, width: "300px" }}>
            <h1 style={{textAlign:"center"}}><b>Vous</b></h1>
            <div className="imgLogin">
              <img src="http://moderation-ugc.s3-website.eu-west-3.amazonaws.com/images/login-logo.png" alt="" className="circle" style={{height:150, width: 150, borderRadius: '100%'}}></img>
            </div>
            <div className="ant-form ant-form-horizontal login-form">
              {
                this.state.loading ? 
                  <div className="ant-row ant-form-item" style={{textAlign: "center"}}><Spin size="large" /></div>
                  :
                  ''
              }
              <div className="ant-row ant-form-item">
                <div className="ant-col ant-form-item-control-wrapper">
                  <div className={`ant-form-item-control ${this.state.errors.indexOf('username_mandatory') !== -1 || this.state.errors.indexOf('UserNotFoundException') !== -1 || this.state.errors.indexOf('NotAuthorizedException') !== -1 ? 'has-error' : '' }`}>
                    <Input 
                      placeholder="Login"   
                      onChange={evt => this.onChange('username', evt.target.value)}
                    />
                    {
                      this.state.errors.indexOf('username_mandatory') !== -1 ?
                      <div className="ant-form-explain">Renseigner un login</div>
                      :
                      ''
                    }
                    {
                      this.state.errors.indexOf('UserNotFoundException') !== -1 ?
                      <div className="ant-form-explain">L'utilisateur n'existe pas</div>
                      :
                      ''
                    }
                    {
                      this.state.errors.indexOf('NotAuthorizedException') !== -1 ?
                      <div className="ant-form-explain">Login ou mot de passe incorrect</div>
                      :
                      ''
                    }
                  </div>
                </div>
              </div>
              <form className="ant-row ant-form-item" onSubmit={this.signIn}>
                <div className="ant-col ant-form-item-control-wrapper">
                  <div className={`ant-form-item-control ${this.state.errors.indexOf('password_mandatory') !== -1 ? 'has-error' : '' }`}>
                    <Input 
                      type="password" 
                      placeholder="Mot de passe" 
                      onChange={evt => this.onChange('password', evt.target.value)}
                    />
                    {
                      this.state.errors.indexOf('username_mandatory') !== -1 ? 
                      <div className="ant-form-explain">Renseigner un mot de passe</div>
                      :
                      ''
                    }
                  </div>
                </div>
              
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-control-wrapper">
                    <div className="ant-form-item-control">
                      <Button type="primary" htmlType="submit" className="login-form-button">
                        Se connecter
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Fait par la Cellule Data &amp; Innovation Le Parisien<br/>
        </Footer>
      </Layout>
    )
  }
}

export default withRouter(Authenticator)
