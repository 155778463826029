module.exports=[
{
"count":536,
"path":"Restaurant ou traiteur",
"state":"displayed",
"name":"Restaurant ou traiteur"
},
{
"count":375,
"path":"Autre",
"state":"displayed",
"name":"Autre"
},
{
"count":169,
"path":"Boucherie - charcuterie - rôtisserie",
"state":"displayed",
"name":"Boucherie - charcuterie - rôtisserie"
},
{
"count":152,
"path":"Primeur",
"state":"displayed",
"name":"Primeur"
},
{
"count":145,
"path":"Alimentation générale et produits de première nécessité",
"state":"displayed",
"name":"Alimentation générale et produits de première nécessité"
},
{
"count":128,
"path":"Librairie",
"state":"displayed",
"name":"Librairie"
},
{
"count":118,
"path":"Boulangerie - pâtisserie",
"state":"displayed",
"name":"Boulangerie - pâtisserie"
},
{
"count":108,
"path":"Commerce de détail de boissons",
"state":"displayed",
"name":"Commerce de détail de boissons"
},
{
"count":103,
"path":"Épicerie fine",
"state":"displayed",
"name":"Épicerie fine"
},
{
"count":76,
"path":"Fromagerie",
"state":"displayed",
"name":"Fromagerie"
},
{
"count":47,
"path":"Fleuriste",
"state":"displayed",
"name":"Fleuriste"
},
{
"count":44,
"path":"Poissonnerie",
"state":"displayed",
"name":"Poissonnerie"
},
{
"count":24,
"path":"Pharmacies et parapharmacies",
"state":"displayed",
"name":"Pharmacies et parapharmacies"
},
{
"count":14,
"path":"Équipements informatiques",
"state":"displayed",
"name":"Équipements informatiques"
},
{
"count":12,
"path":"Bricolage",
"state":"displayed",
"name":"Bricolage"
},
{
"count":10,
"path":"Articles médicaux et orthopédiques",
"state":"displayed",
"name":"Articles médicaux et orthopédiques"
},
{
"count":10,
"path":"Presse et papeterie",
"state":"displayed",
"name":"Presse et papeterie"
},
{
"count":8,
"path":"Disquaire",
"state":"displayed",
"name":"Disquaire"
},
{
"count":5,
"path":"Blanchisserie-teinturerie",
"state":"displayed",
"name":"Blanchisserie-teinturerie"
}
]