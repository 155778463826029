import React from 'react'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import {
  Form, Input, Button, Spin
} from 'antd';
import { Layout } from 'antd';
const { Content, Footer } = Layout;

class SignUp extends React.Component {

  state = {
    username: '',
    password: '',
    authenticationCode: '',
    loading: false,
    errors: [],
    step: 1
  }

  constructor(props) {
    super(props);

    const { history } = this.props;
    Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log(user)
      history.push('/')
    })
    .catch((err) => console.log(err))
  }


  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  signUp = (e) => {

    e.preventDefault()

    var errors = []
    if (this.state.username == '') {
      errors.push('username_mandatory') 
    }
    
    if (this.state.password == '') {
      errors.push('password_mandatory')
    }

    if (errors.length > 0) {
      this.setState({
        errors: errors
      })
    } else {
      this.setState({
        loading: true
      }, () => {
        const { username, password } = this.state

        Auth.signUp({ username, password, attributes: { email: username } })
          .then(user => {
            this.setState({ 
              loading: false,
              step: 1 
            })
          })
          .catch(err => {
            var errors = this.state.errors
            errors.push(err.code)
            console.log('error signing in...: ', err, errors)
            this.setState({
              loading: false,
              errors: errors
            })
          })
      })
    }
  }

  confirmSignUp = (e) => {

    e.preventDefault()

    var errors = []
    if (this.state.username == '') {
      errors.push('username_mandatory') 
    }
    
    if (this.state.authenticationCode == '') {
      errors.push('authentication_code_mandatory')
    }

    if (errors.length > 0) {
      this.setState({
        errors: errors
      })
    } else {
      this.setState({
        loading: true
      }, () => {
        const { username, authenticationCode } = this.state

        Auth.confirmSignUp(username, authenticationCode)
          .then(user => {
            this.props.history.push('/login')
          })
          .catch(err => {
            var errors = this.state.errors
            errors.push(err.code)
            console.log('error confirmSignUp in...: ', err, errors)
            this.setState({
              loading: false,
              errors: errors
            })
          })
      })
    }
  }

  render() {
    return (
      <Layout className="layout">
        <Content style={{ padding: '50px 50px 0px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ background: '#fff', padding: 24, minHeight: 280, width: "300px" }}>
            <h1 style={{textAlign:"center"}}><b>Inscription</b></h1>
            <div className="imgLogin">
              <img src="http://moderation-ugc.s3-website.eu-west-3.amazonaws.com/images/login-logo.png" alt="" className="circle" style={{height:150, width: 150, borderRadius: '100%'}}></img>
            </div>
            <div className="ant-form ant-form-horizontal login-form">
              {
                this.state.loading ? 
                  <div className="ant-row ant-form-item" style={{textAlign: "center"}}><Spin size="large" /></div>
                  :
                  ''
              }
              {
                this.state.step == 0 && (
                  <form className="ant-row ant-form-item" onSubmit={this.signUp}>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-control-wrapper">
                        <div className={`ant-form-item-control ${this.state.errors.indexOf('username_mandatory') !== -1 || this.state.errors.indexOf('UserNotFoundException') !== -1 || this.state.errors.indexOf('NotAuthorizedException') !== -1 ? 'has-error' : '' }`}>
                          <Input 
                            placeholder="Login"   
                            onChange={evt => this.onChange('username', evt.target.value)}
                          />
                          {
                            this.state.errors.indexOf('username_mandatory') !== -1 ?
                            <div className="ant-form-explain">Renseigner un login</div>
                            :
                            ''
                          }
                          {
                            this.state.errors.indexOf('UserNotFoundException') !== -1 ?
                            <div className="ant-form-explain">L'utilisateur n'existe pas</div>
                            :
                            ''
                          }
                          {
                            this.state.errors.indexOf('NotAuthorizedException') !== -1 ?
                            <div className="ant-form-explain">Login ou mot de passe incorrect</div>
                            :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-form-item-control-wrapper">
                      <div className={`ant-form-item-control ${this.state.errors.indexOf('password_mandatory') !== -1 ? 'has-error' : '' }`}>
                        <Input 
                          type="password" 
                          placeholder="Mot de passe" 
                          onChange={evt => this.onChange('password', evt.target.value)}
                        />
                        {
                          this.state.errors.indexOf('username_mandatory') !== -1 ? 
                          <div className="ant-form-explain">Renseigner un mot de passe</div>
                          :
                          ''
                        }
                      </div>
                    </div>
                  
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-control-wrapper">
                        <div className="ant-form-item-control">
                          <Button type="primary" htmlType="submit" className="login-form-button">
                            S'inscrire
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }

              {
                this.state.step == 1 && (

                  <form className="ant-row ant-form-item" onSubmit={this.confirmSignUp}>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-control-wrapper">
                        <div className={`ant-form-item-control ${this.state.errors.indexOf('username_mandatory') !== -1 || this.state.errors.indexOf('UserNotFoundException') !== -1 || this.state.errors.indexOf('NotAuthorizedException') !== -1 ? 'has-error' : '' }`}>
                          <Input 
                            placeholder="Login"   
                            onChange={evt => this.onChange('username', evt.target.value)}
                          />
                          {
                            this.state.errors.indexOf('username_mandatory') !== -1 ?
                            <div className="ant-form-explain">Renseigner un login</div>
                            :
                            ''
                          }
                          {
                            this.state.errors.indexOf('UserNotFoundException') !== -1 ?
                            <div className="ant-form-explain">L'utilisateur n'existe pas</div>
                            :
                            ''
                          }
                          {
                            this.state.errors.indexOf('NotAuthorizedException') !== -1 ?
                            <div className="ant-form-explain">Login ou mot de passe incorrect</div>
                            :
                            ''
                          }
                        </div>
                      </div>
                    </div>
                    <div className="ant-col ant-form-item-control-wrapper">
                      <div className={`ant-form-item-control ${this.state.errors.indexOf('authentication_code_mandatory') !== -1 ? 'has-error' : '' }`}>
                        <Input 
                          placeholder="Code d'authentification" 
                          onChange={evt => this.onChange('authenticationCode', evt.target.value)}
                        />
                        {
                          this.state.errors.indexOf('authentication_code_mandatory') !== -1 ? 
                          <div className="ant-form-explain">Renseigner le code d'authentification reçu par mail</div>
                          :
                          ''
                        }
                      </div>
                    </div>
                  
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-control-wrapper">
                        <div className="ant-form-item-control">
                          <Button type="primary" htmlType="submit" className="login-form-button">
                            Confirmer l'inscription
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Fait par la Cellule Data &amp; Innovation Le Parisien<br/>
        </Footer>
      </Layout>
    )
  }
}

export default withRouter(SignUp)
