import React, { PureComponent } from "react";
import { Layout, Tabs, Row, Col } from 'antd';
import filter from 'lodash.filter';
import Items from "./components/Items";
import Title from "antd/lib/typography/Title";
const { TabPane } = Tabs;

export default class ProfilList extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      profils: null
    }
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {

    fetch(`https://8u3okfqop4.execute-api.eu-west-1.amazonaws.com/production/galaxies?type=${this.props.match.params.type_slug.slice(0,1).toUpperCase()}`)
    .then(response => response.json() )
    .then((responseJson) => {

      this.setState({
        isLoading: false,
        profils: responseJson.slice(0,100)
      })
    })
  }

  filterRelation = (value) => {
    var filt = '';

    if (value == 'mort') {
      filt = 'Mort'
    } else if (value == 'prison') {
      filt = 'prison'
    } else if (value == 'syrie') {
      filt = 'En Syrie ou Irak'
    } else if (value == 'rech') {
      filt = 'Recherché'
    } else if (value == 'lib') {
      filt = 'Liberté restreinte'
    }

    this.setState({
      profil_relations: filt != '' ? filter(this.state.profil.entries, { entry_type: 'P', entry_statut: filt }) : filter(this.state.profil.entries, { entry_type: 'P' })
    })
  }


  render() {

    var activeKey = "0";

    if (this.state.profils) {
      if (this.props.match.params.type_slug.slice(0,1).toUpperCase() === 'P') {
        activeKey = "2"
      } else if (this.props.match.params.type_slug.slice(0,1).toUpperCase() === 'F') {
        activeKey = "3"
      } else if (this.props.match.params.type_slug.slice(0,1).toUpperCase() === 'E') {
        activeKey = "4"
      } 
    }

    return (
      <Layout className="galaxie-app">
        <div className="header-partner">
          <header className="header header_small container-fluid">
            <div className="table header__table">
              <div className="header__top cell collapse950"></div>
            <div className="header__top header__top_logo cell">
              <a href="https://www.leparisien.fr">
                <img className="sticky__logo_small-image" src="https://s1.lprs1.fr/assets/img/logo/E-LOGO-LP-136x40.svg" />
              </a>
            </div>
            <div className="header__top cell collapse950">
              <a href="https://www.leparisien.fr/mon-compte/" className="header__icon-menu icon icon_medium icon-Avatar-24 icon-24"></a>
              <a href="https://abonnement.leparisien.fr/?origin=LP19HEAD&utm_medium=ancrage&utm_source=internal&utm_campaign=header_suscribe-button" className="header__button-premium button button_yellow">s'abonner</a>
            </div>
          </div>
          </header>
        </div>
        <Row className="galaxie-title">
          <Col span={24}>
            <Title level={3}>La galaxie djihadiste</Title>
          </Col>
        </Row>
        
        <Row>
          <Col span={24}>
            <Tabs activeKey={activeKey} centered>
              <TabPane tab={<a href="/galaxie-djihadiste/">Accueil</a>} key="1">
                <Items profils={this.state.profils} profil_relations={this.state.profil_relations} />
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/protagonistes">Protagonistes</a>} key="2">
                <Items profils={this.state.profils} loading={this.state.isLoading} items={['date','impliques']} />
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/filieres">Filières</a>} key="3">
                <Items profils={this.state.profils} loading={this.state.isLoading} items={['date','impliques']} />
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/evenements">Événements</a>} key="4">
                <Items profils={this.state.profils} loading={this.state.isLoading} items={['status','impliques']} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Layout>
    )
  }
}