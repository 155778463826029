import React from 'react';
import { Row, Col, Image, Card, Avatar, List, Spin } from 'antd';
import filter from 'lodash.filter';
import Meta from "antd/lib/card/Meta";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { FlagOutlined, CalendarOutlined, ContainerOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ItemList from "./ItemList";

class Item extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      profil,
      profil_relations
    } = this.props;

    return (
      <Row>
        <Col span={24}>
          {
            profil && profil_relations ?
              <Card>
                <Meta
                  avatar={<Avatar shape="square" style={{borderRadius:'5px'}} size={64} src={`https://atelier.leparisien.fr/galaxie-djihadiste/photos/${profil.image != "" ? profil.image : 'avatar.jpg'}`} />}
                  title={<div>
                    <Title level={4}>{profil.name}</Title>
                    <List
                      style={{marginTop: "10px"}}
                      itemLayout="horizontal"
                      dataSource={[{
                        title: <span className="subItem"><b>Nationalité :</b> {profil.nation != "" ? profil.nation : "N.C."}</span>,
                        avatar: <FlagOutlined />,
                        visible: ['P'].indexOf(profil.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Date de naissance :</b> {profil.datenaissance != "" ? profil.datenaissance : "N.C."}</span>,
                        avatar: <CalendarOutlined />,
                        visible: ['P'].indexOf(profil.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Statut :</b> {profil.statut != "" ? profil.statut : "N.C."}</span>,
                        avatar: <QuestionCircleOutlined />,
                        visible: ['E', 'F', 'P'].indexOf(profil.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Date :</b> {profil.datedelattaque != "" ? profil.datedelattaque : "N.C."}</span>,
                        avatar: <CalendarOutlined />,
                        visible: ['E', 'F'].indexOf(profil.TYPE) !== -1
                      }]}
                      renderItem={item => (
                        item.visible && <List.Item>
                          <List.Item.Meta
                            avatar={item.avatar}
                            title={item.title}
                          />
                        </List.Item>
                      )}
                    />
                  </div>}
                />
                {
                  ['F','E'].indexOf(profil.TYPE) !== -1 && 

                  <Row style={{marginTop: "10px"}}>
                    <Col span={24}>
                      <Image width={"100%"} src={`https://atelier.leparisien.fr/galaxie-djihadiste/images/${profil.img_carte != "" ? profil.img_carte : 'avatar.jpg'}`} />
                    </Col>
                  </Row>
                }
                <Row style={{marginTop: "10px"}}>
                  <Col span={24}>
                    <Paragraph>{profil.texte != "" ? profil.texte : "N.C."}</Paragraph>
                  </Col>
                </Row>
                {

                filter(profil.entries, { entry_type: 'E'}).length > 0 && 
                  <ItemList profil={profil} entry_type={"E"} title={'Événement(s)'} items={['date','impliques']} dataSource={filter(profil.entries, { entry_type: 'E'})} />
                }
                {
                filter(profil.entries, { entry_type: 'F'}).length > 0 &&
                  <ItemList profil={profil} entry_type={"F"} title={'Filière(s)'} items={['date','impliques']} dataSource={filter(profil.entries, { entry_type: 'F'})} />
                }
                {
                filter(profil.entries, { entry_type: 'P'}).length > 0 &&
                  <ItemList profil={profil} entry_type={"P"} title={'Relation(s)'} items={['status','impliques']} filterRelation={(value)=>this.filterRelation(value)} dataSource={profil_relations} />
                }
              </Card>
            :
            <Spin />
          }
        </Col>
      </Row>
    )
  }
}

export default Item;