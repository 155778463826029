/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchByDistance = /* GraphQL */ `
  query SearchByDistance(
    $place: PlaceInput!
    $km: Int
    $limit: Int
    $nextToken: Int
    $status: Int
    $hasleparisien: Boolean
    $hasmask: Boolean
  ) {
    searchByDistance(
      place: $place
      km: $km
      limit: $limit
      nextToken: $nextToken
      status: $status
      hasleparisien: $hasleparisien
      hasmask: $hasmask
    ) {
      items {
        place {
          id
          slug
          title
          content
          activity
          description
          type
          status
          hours {
            hours_type
            is_open_now
          }
          rating
          is_claimed
          owner
          formatted_address
          postal_code
          has_mask
          has_leparisien
          location {
            address
            postal_code
            city
            country
            formatted_address
          }
          coordinates {
            lat
            lon
          }
          ordersLV {
            nextToken
          }
        }
        distance
      }
      total
      nextToken
    }
  }
`;
export const searchTerrassePlaceParisByDistance = /* GraphQL */ `
  query SearchTerrassePlaceParisByDistance(
    $place: PlaceInput
    $km: Int
    $limit: Int
    $nextToken: Int
    $status: Int
    $type: String
  ) {
    searchTerrassePlaceParisByDistance(
      place: $place
      km: $km
      limit: $limit
      nextToken: $nextToken
      status: $status
      type: $type
    ) {
      items {
        place {
          id
          slug
          title
          coordinates {
            lat
            lon
          }
          address_1
          type_1
          address_2
          type_2
          pdf
          terrasse_type
          geo_shape
          longueur
          largeur_min
          largeur_max
          surface_min
          surface_max
        }
        distance
      }
      total
      nextToken
    }
  }
`;
export const searchSitePrelevementByDistance = /* GraphQL */ `
  query SearchSitePrelevementByDistance(
    $place: PlaceInput
    $km: Int
    $limit: Int
    $nextToken: Int
    $status: Int
    $type: String
  ) {
    searchSitePrelevementByDistance(
      place: $place
      km: $km
      limit: $limit
      nextToken: $nextToken
      status: $status
      type: $type
    ) {
      items {
        place {
          id
          slug
          title
          coordinates {
            lat
            lon
          }
          address_1
          type_1
          address_2
          type_2
          pdf
          terrasse_type
          geo_shape
          longueur
          largeur_min
          largeur_max
          surface_min
          surface_max
        }
        distance
      }
      total
      nextToken
    }
  }
`;
export const searchClickCollectByDistance = /* GraphQL */ `
  query SearchClickCollectByDistance(
    $place: PlaceInput!
    $km: Int
    $limit: Int
    $nextToken: Int
    $status: Int
    $is_delivrable_and_collect: Boolean
    $is_clickncollect: Boolean
    $is_delivrable: Boolean
    $type: String
  ) {
    searchClickCollectByDistance(
      place: $place
      km: $km
      limit: $limit
      nextToken: $nextToken
      status: $status
      is_delivrable_and_collect: $is_delivrable_and_collect
      is_clickncollect: $is_clickncollect
      is_delivrable: $is_delivrable
      type: $type
    ) {
      items {
        place {
          id
          slug
          name
          description
          type
          status
          website_url
          phone
          mail
          formatted_address
          postal_code
          is_delivrable
          is_clickncollect
          location {
            address
            postal_code
            city
            country
            formatted_address
          }
          coordinates {
            lat
            lon
          }
        }
        distance
      }
      total
      nextToken
    }
  }
`;
export const getPlace = /* GraphQL */ `
  query GetPlace($id: ID!) {
    getPlace(id: $id) {
      id
      slug
      title
      content
      activity
      description
      type
      status
      hours {
        hours_type
        is_open_now
        open {
          day
          is_overnight
          start
          end
        }
      }
      rating
      is_claimed
      owner
      formatted_address
      postal_code
      has_mask
      has_leparisien
      location {
        address
        postal_code
        city
        country
        formatted_address
      }
      coordinates {
        lat
        lon
      }
      ordersLV {
        items {
          id
          placeSlug
          date
        }
        nextToken
      }
    }
  }
`;
export const listPlaces = /* GraphQL */ `
  query ListPlaces(
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        title
        content
        activity
        description
        type
        status
        hours {
          hours_type
          is_open_now
          open {
            day
            is_overnight
            start
            end
          }
        }
        rating
        is_claimed
        owner
        formatted_address
        postal_code
        has_mask
        has_leparisien
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
        ordersLV {
          items {
            id
            placeSlug
            date
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTerrassePlaceParis = /* GraphQL */ `
  query GetTerrassePlaceParis($id: ID!) {
    getTerrassePlaceParis(id: $id) {
      id
      slug
      title
      coordinates {
        lat
        lon
      }
      address_1
      type_1
      address_2
      type_2
      pdf
      terrasse_type
      geo_shape
      longueur
      largeur_min
      largeur_max
      surface_min
      surface_max
    }
  }
`;
export const listTerrassePlacePariss = /* GraphQL */ `
  query ListTerrassePlacePariss(
    $filter: ModelTerrassePlaceParisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerrassePlacePariss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        coordinates {
          lat
          lon
        }
        address_1
        type_1
        address_2
        type_2
        pdf
        terrasse_type
        geo_shape
        longueur
        largeur_min
        largeur_max
        surface_min
        surface_max
      }
      nextToken
    }
  }
`;
export const getClickCollectPlace = /* GraphQL */ `
  query GetClickCollectPlace($id: ID!) {
    getClickCollectPlace(id: $id) {
      id
      slug
      name
      description
      type
      status
      website_url
      phone
      mail
      formatted_address
      postal_code
      is_delivrable
      is_clickncollect
      location {
        address
        postal_code
        city
        country
        formatted_address
      }
      coordinates {
        lat
        lon
      }
    }
  }
`;
export const listClickCollectPlaces = /* GraphQL */ `
  query ListClickCollectPlaces(
    $filter: ModelClickCollectPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClickCollectPlaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        description
        type
        status
        website_url
        phone
        mail
        formatted_address
        postal_code
        is_delivrable
        is_clickncollect
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      placeSlug
      date
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        placeSlug
        date
      }
      nextToken
    }
  }
`;
export const placeByType = /* GraphQL */ `
  query PlaceByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placeByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        content
        activity
        description
        type
        status
        hours {
          hours_type
          is_open_now
          open {
            day
            is_overnight
            start
            end
          }
        }
        rating
        is_claimed
        owner
        formatted_address
        postal_code
        has_mask
        has_leparisien
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
        ordersLV {
          items {
            id
            placeSlug
            date
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const placeByPostalCode = /* GraphQL */ `
  query PlaceByPostalCode(
    $postal_code: String
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placeByPostalCode(
      postal_code: $postal_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        content
        activity
        description
        type
        status
        hours {
          hours_type
          is_open_now
          open {
            day
            is_overnight
            start
            end
          }
        }
        rating
        is_claimed
        owner
        formatted_address
        postal_code
        has_mask
        has_leparisien
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
        ordersLV {
          items {
            id
            placeSlug
            date
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clickCollectPlaceByType = /* GraphQL */ `
  query ClickCollectPlaceByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelClickCollectPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clickCollectPlaceByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        description
        type
        status
        website_url
        phone
        mail
        formatted_address
        postal_code
        is_delivrable
        is_clickncollect
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
      }
      nextToken
    }
  }
`;
export const clickCollectPlaceByPostalCode = /* GraphQL */ `
  query ClickCollectPlaceByPostalCode(
    $postal_code: String
    $sortDirection: ModelSortDirection
    $filter: ModelClickCollectPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clickCollectPlaceByPostalCode(
      postal_code: $postal_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        description
        type
        status
        website_url
        phone
        mail
        formatted_address
        postal_code
        is_delivrable
        is_clickncollect
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
      }
      nextToken
    }
  }
`;
export const searchPlaces = /* GraphQL */ `
  query SearchPlaces(
    $filter: SearchablePlaceFilterInput
    $sort: SearchablePlaceSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPlaces(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        content
        activity
        description
        type
        status
        hours {
          hours_type
          is_open_now
          open {
            day
            is_overnight
            start
            end
          }
        }
        rating
        is_claimed
        owner
        formatted_address
        postal_code
        has_mask
        has_leparisien
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
        ordersLV {
          items {
            id
            placeSlug
            date
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchTerrassePlacePariss = /* GraphQL */ `
  query SearchTerrassePlacePariss(
    $filter: SearchableTerrassePlaceParisFilterInput
    $sort: SearchableTerrassePlaceParisSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchTerrassePlacePariss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        coordinates {
          lat
          lon
        }
        address_1
        type_1
        address_2
        type_2
        pdf
        terrasse_type
        geo_shape
        longueur
        largeur_min
        largeur_max
        surface_min
        surface_max
      }
      nextToken
      total
    }
  }
`;
export const searchClickCollectPlaces = /* GraphQL */ `
  query SearchClickCollectPlaces(
    $filter: SearchableClickCollectPlaceFilterInput
    $sort: SearchableClickCollectPlaceSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchClickCollectPlaces(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        description
        type
        status
        website_url
        phone
        mail
        formatted_address
        postal_code
        is_delivrable
        is_clickncollect
        location {
          address
          postal_code
          city
          country
          formatted_address
        }
        coordinates {
          lat
          lon
        }
      }
      nextToken
      total
    }
  }
`;
