import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter
} from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Authenticator from './Authenticator'
//import Home from './pages/Home'
import ListByPostalCode from './pages/ListByPostalCode'

import Exception from './pages/Exception'
import SignUp from './SignUp'
import ListByDistance from './pages/ListByDistance'
import ListByDistanceMask from './pages/ListByDistanceMask'
import TerrasseListByDistance from './pages/TerrasseListByDistance'
import SitePrelevementByDistance from './pages/SitePrelevementByDistance'

import EventEmbed from './pages/galaxie/EventEmbed'
import Event from './pages/galaxie/Event'
import ProfilEmbed from './pages/galaxie/ProfilEmbed'
import Profil from './pages/galaxie/Profil'
import Home from './pages/galaxie/Home'
import TeamEmbed from './pages/galaxie/TeamEmbed'
import Team from './pages/galaxie/Team'
import ProfilList from './pages/galaxie/ProfilList'
import ClickCollectListByDistance from './pages/ClickCollectListByDistance'

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: null
  }
  componentDidMount() {
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .catch(() => {
          if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })
        })
    });
  }
  componentWillUnmount() {
    this.unlisten()
  }
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ loaded: true, isAuthenticated: true, user: user })
      })
      .catch(() => this.props.history.push('/'))
  }
  render() {
    const { component: Component, ...rest } = this.props
    const { loaded , isAuthenticated} = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} user={this.state.user}/>
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }}
      />
    )
  }
}

PrivateRoute = withRouter(PrivateRoute)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/login' component={Authenticator} />
      <Route exact path='/s-inscrire' component={SignUp} />
      <Route exact path='/sites-prelevement-covid' component={SitePrelevementByDistance} />
      <Route exact path='/commerces-clickncollect' component={ClickCollectListByDistance} />
      <Route exact path='/terrasses-a-paris' component={TerrasseListByDistance} />
      <Route exact path='/masques-11-mai' component={ListByDistanceMask} />
      <Route exact path="/galaxie-djihadiste/embed/:profil_slug/:profil_id" component={ProfilEmbed} />
      <Route exact path="/galaxie-djihadiste/:profil_slug/:profil_id" component={Profil} />
      <Route exact path="/galaxie-djihadiste/:type_slug" component={ProfilList} />
      <Route exact path="/galaxie-djihadiste/" component={Home} />
      <Route exact path='/' component={ListByDistance} />
      <Route component={Exception} />
    </Switch>
  </BrowserRouter>
)

export default Routes