import React from 'react';
import { Row, Col, List, Avatar, Select } from 'antd';
import Title from "antd/lib/typography/Title";
import { FlagOutlined, CalendarOutlined, ContainerOutlined, QuestionCircleOutlined, TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons';
const { Option } = Select;

class ItemList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      dataSource,
      entry_type,
      title
    } = this.props;

    return (
      <Row style={{marginTop: "10px"}}>
        <Col span={24}>
          <Title level={5}>{title}</Title>
          {
            entry_type === 'P' &&
            <Select style={{width: '100%'}} defaultValue={"tous"} onChange={(value)=>this.props.filterRelation(value)}>
              <Option key="tous" value="tous">Tous</Option>
              <Option key="mort" value="mort">Morts</Option>
              <Option key="prison" value="prison">En prison</Option>
              <Option key="syrie" value="syrie">En Syrie ou Irak</Option>
              <Option key="rech" value="rech">Recherchés</Option>
              <Option key="lib" value="lib">Liberté restreinte</Option>
            </Select>
          }
          <List
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={item => (
              <List.Item className="ant-list-item-margin">
                <List.Item.Meta
                  avatar={<Avatar shape="square" size={64} src={`https://atelier.leparisien.fr/galaxie-djihadiste/photos/${item.entry_image != "" ? item.entry_image : 'avatar.jpg'}`} />}
                  title={<a className="ant-list-item-meta-title-bold" href={`/galaxie-djihadiste/${item.entry_slug}/${item.entry_id}`}>{item.entry_name}</a>}
                  description={<div>
                    <List
                      itemLayout="horizontal"
                      dataSource={[{
                        title: <span className="subItem"><b>Date :</b> {item.entry_date != "" ? item.entry_date : "N.C."}</span>,
                        avatar: <CalendarOutlined />,
                        visible: ['E'].indexOf(item.entry_type) !== -1
                      }, {
                        title: <span className="subItem"><b>Impliqué(s) :</b> {item.entry_total_p != 0 ? item.entry_total_p + " personnes": "N.C."}</span>,
                        avatar: <UsergroupAddOutlined />,
                        visible: ['E', 'F'].indexOf(item.entry_type) !== -1
                      }, {
                        title: <span className="subItem"><b>Statut :</b> {item.entry_statut != "" ? item.entry_statut : "N.C."}</span>,
                        avatar:  <QuestionCircleOutlined />,
                        visible: ['P'].indexOf(item.entry_type) !== -1
                      }, {
                        title: <span className="subItem"><b>Relation(s) :</b> {item.entry_total_p != 0 ? item.entry_total_p + " personnes": "N.C."}</span>,
                        avatar: <TeamOutlined />,
                        visible: ['P'].indexOf(item.entry_type) !== -1
                      }]}
                      renderItem={item => (
                        item.visible && <List.Item>
                          <List.Item.Meta
                            avatar={item.avatar}
                            title={item.title}
                          />
                        </List.Item>
                      )}
                    />
                  </div>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  }
}

export default ItemList;