// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:e96efb0d-a1e4-415f-b0fb-adeedda4b4fb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Uuq1LEmoq",
    "aws_user_pools_web_client_id": "4cblboretk8b36phtthf70qloc",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://bxjwd7pkmba2znqyvsbt7ygfwe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-c4r6iqd74fhnbauocw7me4aokm",
    "aws_content_delivery_bucket": "lpannuaire-20200417190427-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://lpannuaire-20200417190427-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
