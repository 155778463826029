import React from 'react';
import { Row, Col, Image, Card, Avatar, List, Spin } from 'antd';
import filter from 'lodash.filter';
import Meta from "antd/lib/card/Meta";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { FlagOutlined, CalendarOutlined, ContainerOutlined, QuestionCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import ItemList from "./ItemList";
import Item from './Item';

export default class Items extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps)
    return {
      loading: nextProps.loading,
    };
  }

  render() {
    const {
      profils,
      title,
      items,
      type
    } = this.props;
    
    return (
      <Row style={{marginTop: "10px"}}>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={profils}
            renderItem={item => (
              <List.Item className="ant-list-item-margin">
                <List.Item.Meta
                  avatar={<Avatar shape="square" size={64} src={`https://atelier.leparisien.fr/galaxie-djihadiste/photos/${item.image != "" ? item.image : 'avatar.jpg'}`} />}
                  title={<a style={{ fontSize: "16px" }} className="ant-list-item-meta-title-bold" href={`/galaxie-djihadiste/${item.slug}/${item.ID}`}>{item.name}</a>}
                  description={<div>
                    <List
                      itemLayout="horizontal"
                      dataSource={[{
                        title: <span className="subItem"><b>Date :</b> {item.datedelattaque != "" ? item.datedelattaque : "N.C."}</span>,
                        avatar: <CalendarOutlined />,
                        visible: ['E'].indexOf(item.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Impliqué(s) :</b> {item.total_entries_p != "" ? item.total_entries_p + " personne" + (item.total_entries_p > 1 ? "s" : "") : "N.C."}</span>,
                        avatar: <UsergroupAddOutlined />,
                        visible: ['E', 'F'].indexOf(item.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Statut :</b> {item.statut != "" ? item.statut : "N.C."}</span>,
                        avatar: <QuestionCircleOutlined />,
                        visible: ['P'].indexOf(item.TYPE) !== -1
                      }, {
                        title: <span className="subItem"><b>Relation(s) :</b> {item.total_entries_p != "" ? item.total_entries_p + " personne" + (item.total_entries_p > 1 ? "s" : "") : "N.C."}</span>,
                        avatar: <FlagOutlined />,
                        visible: ['P'].indexOf(item.TYPE) !== -1
                      }]}
                      renderItem={item => (
                        item.visible && <List.Item>
                          <List.Item.Meta
                            avatar={item.avatar}
                            title={item.title}
                          />
                        </List.Item>
                      )}
                    />
                  </div>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    )
  }
}