import React, { PureComponent } from "react";
import { Layout, Tabs, Row, Col, Image } from 'antd';
import filter from 'lodash.filter';
import Item from "./components/Item";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import Items from "./components/Items";
const { TabPane } = Tabs;

export default class Home extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isLoadingEvent: true,
      isLoadingProtag: true,
      isLoadingFiliere: true,
      profil: null
    }
  }

  componentDidMount = () => {
    this.fetchEvent();
  }

  fetchEvent = () => {

    fetch(`https://8u3okfqop4.execute-api.eu-west-1.amazonaws.com/production/galaxies?type=E&limit=3`)
    .then(response => response.json() )
    .then((responseJson) => {

      this.setState({
        isLoadingEvent: false,
        events: filter(responseJson, { TYPE: 'E'}).slice(0,3)
      }, () => this.fetchProtag())
    })
  }

  fetchProtag = () => {

    fetch(`https://8u3okfqop4.execute-api.eu-west-1.amazonaws.com/production/galaxies?type=P&limit=3`)
    .then(response => response.json() )
    .then((responseJson) => {

      this.setState({
        isLoadingProtag: false,
        protag: filter(responseJson, { TYPE: 'P'}).slice(0,3)
      }, () => this.fetchFiliere())
    })
  }

  fetchFiliere = () => {

    fetch(`https://8u3okfqop4.execute-api.eu-west-1.amazonaws.com/production/galaxies?type=F&limit=3`)
    .then(response => response.json() )
    .then((responseJson) => {

      this.setState({
        isLoadingFiliere: false,
        filieres: filter(responseJson, { TYPE: 'F'}).slice(0,3)
      })
    })
  }

  filterRelation = (value) => {
    var filt = '';

    if (value == 'mort') {
      filt = 'Mort'
    } else if (value == 'prison') {
      filt = 'prison'
    } else if (value == 'syrie') {
      filt = 'En Syrie ou Irak'
    } else if (value == 'rech') {
      filt = 'Recherché'
    } else if (value == 'lib') {
      filt = 'Liberté restreinte'
    }

    this.setState({
      profil_relations: filt != '' ? filter(this.state.profil.entries, { entry_type: 'P', entry_statut: filt }) : filter(this.state.profil.entries, { entry_type: 'P' })
    })
  }


  render() {

    var activeKey = "0";

    if (this.state.profil) {
      if (this.state.profil.TYPE === 'P') {
        activeKey = "2"
      } else if (this.state.profil.TYPE === 'F') {
        activeKey = "3"
      } else if (this.state.profil.TYPE === 'E') {
        activeKey = "4"
      } 
    }

    return (
      <Layout className="galaxie-app">
        <div className="header-partner">
          <header className="header header_small container-fluid">
            <div className="table header__table">
              <div className="header__top cell collapse950"></div>
            <div className="header__top header__top_logo cell">
              <a href="https://www.leparisien.fr">
                <img className="sticky__logo_small-image" src="https://s1.lprs1.fr/assets/img/logo/E-LOGO-LP-136x40.svg" />
              </a>
            </div>
            <div className="header__top cell collapse950">
              <a href="https://www.leparisien.fr/mon-compte/" className="header__icon-menu icon icon_medium icon-Avatar-24 icon-24"></a>
              <a href="https://abonnement.leparisien.fr/?origin=LP19HEAD&utm_medium=ancrage&utm_source=internal&utm_campaign=header_suscribe-button" className="header__button-premium button button_yellow">s'abonner</a>
            </div>
          </div>
          </header>
        </div>

        <Row className="galaxie-title">
          <Col span={24}>
            <Title level={3}>La galaxie djihadiste</Title>
          </Col>
        </Row>
        
        <Row>
          <Col span={24}>
            <Tabs activeKey={"1"} centered>
              <TabPane tab={<a href="/galaxie-djihadiste/">Accueil</a>} key="1" style={{padding: "24px"}}>
                <Row>
                  <Col span={24}>
                    <Image width={"100%"} src={`https://photos.lci.fr/images/1280/720/les-accuses-dans-le-proces-de-charlie-hebdo-dans-le-box-1475d8-0@1x.jpeg`} />
                  </Col>
                </Row>
                <Row style={{marginTop: "10px"}}>
                  <Col>
                    <Title level={4}>47 événements, 235 protagonistes et 27 filières passés au crible</Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Paragraph>Une nébuleuse complexe. Si la théorie du loup solitaire a beaucoup circulé sur l'affaire Merah en 2012, les attentats de janvier et novembre 2015 à Paris, puis ceux de mars 2016 à Bruxelles ont au contraire montré combien les protagonistes du djihadisme sont liés</Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Title level={5} style={{fontSize: "18px"}}>Événements</Title>
                    <Items profils={this.state.events} loading={this.state.isLoadingEvent} items={['date','impliques']} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Title level={5} style={{fontSize: "18px"}}>Filières</Title>
                    <Items profils={this.state.filieres} loading={this.state.isLoadingFiliere} items={['impliques']} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Title level={5} style={{fontSize: "18px"}}>Protagonistes</Title>
                    <Items profils={this.state.protag} loading={this.state.isLoadingProtag} items={['status','impliques']} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/protagonistes">Protagonistes</a>} key="2">
                
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/filieres">Filières</a>} key="3">
                
              </TabPane>
              <TabPane tab={<a href="/galaxie-djihadiste/evenements">Événements</a>} key="4">
                
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Layout>
    )
  }
}