import React from 'react';
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Input, Row, Col, Button, List, Layout, Select, Spin, PageHeader, Pagination } from 'antd';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import dayjs from 'dayjs'
import { AutoComplete } from 'antd';
import topojson_france_departement from '../topojson/france-departement'

require('dayjs/locale/fr')
export const pointerIcon = new L.Icon({
  iconUrl: 'https://assets.livemixr.com/images/green-point.png',
  iconRetinaUrl: 'https://assets.livemixr.com/images/green-point.png',
  iconSize: [15, 15],
})

export const pointerRedIcon = new L.Icon({
  iconUrl: 'https://assets.livemixr.com/images/red-point.png',
  iconRetinaUrl: 'https://assets.livemixr.com/images/red-point.png',
  iconSize: [15, 15],
})

const { Content } = Layout;

const { Option } = AutoComplete;

const country_options = {}

country_options['France'] = {
  path: topojson_france_departement,
  location_title: " en France",
  projection: "geoMercator",
  projectionConfig: {
    center: [2.344683, 48.8024005],
    scale: 24000
  }
}

class TerrasseListByDistance extends React.Component {
  state = { 
    distance: 500, 
    coordinates: "48.853362,2.287557",
    nextToken: 0,
    total: 0,
    isLoading: false,
    fetching: true,
    status: null,
    popupLoading: false,
    places: [],
    limit: 10,
    postal_code: '94270',
    search: [],
    auto: [],
    page: 1
  };

  componentDidMount() {
    this.loadByDistance();
  }

  handleSearch = value => {
    this.setState({
      auto: [],

    }, () => {
    
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}&limit=5&lat=48.866667&lon=2.333333&citycode=75056`)
      .then(response => response.json())
      .then((body) => {
        const data = body.features.map(res => ({
          text: `${res.properties.label}`,
          value: res.geometry.coordinates[1] + "," + res.geometry.coordinates[0],
          key: res.properties.id,
        }));
        this.setState({ auto: data, fetching: false });
      });
      
    })
  };

  loadByDistance() {

    this.setState({
      isLoading: true
    }, () => {
      var spl = this.state.coordinates.split(',')

      var coordinates = {
        lat: parseFloat(spl[0]),
        lon: parseFloat(spl[1])
      }

      API.graphql(
        graphqlOperation(queries.searchTerrassePlaceParisByDistance, {
          place: coordinates,
          km: this.state.distance,
          nextToken: this.state.nextToken,
          status: 1,
          limit: this.state.limit
        })
      )
      .then(result => {

        this.setState({
          isLoading: false,
          places: result.data.searchTerrassePlaceParisByDistance.items,
          nextToken: result.data.searchTerrassePlaceParisByDistance.nextToken,
          total: result.data.searchTerrassePlaceParisByDistance.total
        });
      })
      .catch(err => console.log(err));
    })
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleSelect = (value) => {
    console.log(value)
    this.setState({
      coordinates: value
    })
  }

  handleSubmit = (value) => {
    this.loadByDistance()
  }

  paginate = (page, pageSize) => {
    console.log(page, pageSize)
    this.setState({
      page: page,
      nextToken: (page-1)*10
    }, () => this.loadByDistance())
  }

  render() {
    const { auto } = this.state;
    const children = auto.map(res => <Option key={res.key} value={res.value}>{res.text}</Option>);
 
    return (
      <Layout style={{ padding: '20px', width: "100%", height: "100%" }} ref={el => (this.componentRef = el)} >
        <Content style={{
          margin: 0, minHeight: 400, width: "100%", height: "100%"
        }}
        >
          <PageHeader title={"Où trouver une terrasse à Paris ?"} subTitle={"Saisir une adresse"}>
            <Row>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <AutoComplete  style={{ width: "100%" }} onSearch={this.handleSearch} onSelect={this.handleSelect} placeholder="10 boulevard de Grenelle 75015 Paris">
                  {children}
                </AutoComplete>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Button style={{ width: "100%" }} type="primary" onClick={ () => this.handleSubmit() } >Rechercher</Button>
              </Col>
              <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}></Col>
            </Row>
          </PageHeader>
          <Row>
            <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}></Col>
            <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>  
            {
              ! this.state.isLoading ?
                <List
                  locale={{
                    emptyText: 'Renseignez une adresse'
                  }}
                  itemLayout="vertical"
                  size="large"
                  dataSource={this.state.places}
                  renderItem={item => (
                    <List.Item
                      key={item.place.id}
                      actions={[
                        `à ${(Math.round(item.distance*10)/10).toString().replace('.', ',')}km`
                      ]}
                    >
                      <List.Item.Meta
                        title={`${item.place.address_1} ${item.place.address_2 != "" ? item.place.address_2 : ''}`}
                        description={<div>
                          <p>{ item.place.terrasse_type == 1 ? 'Terrasse ouverte' : ( item.place.terrasse_type == 2 ? 'Terrasse fermée' : '') } - Surface : {item.place.surface_max}m2</p>
                          <p><a href={item.place.pdf.split(',').join('.')} target="_blank" rel="noopener noreferrer">Voir la fiche de l'établissement</a></p>
                        </div>}
                      />
                    </List.Item>
                  )}
                />
              :
                <div className="planeLoader"><Spin size="large" /></div>
            }
            {
              ! this.state.isLoading ? 
                <Pagination defaultCurrent={this.state.page} total={this.state.total} onChange={(page, pageSize) => this.paginate(page, pageSize)} />
              :
                ''
            }
            </Col>
            <Col className="layout-row-col" id="layout-row-8-col-1-charts" xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default TerrasseListByDistance;
