import React, { createElement } from 'react';
import { Button } from 'antd';

class Exception extends React.PureComponent {
  static defaultProps = {
    backText: 'Retour à l\'accueil',
    redirect: '/',
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      className,
      backText,
      linkElement = 'a',
      type,
      title,
      desc,
      img,
      actions,
      redirect,
      ...rest
    } = this.props;

    return (
      <div className="exception" {...rest}>
        <div className="content">
          <h1>404</h1>
          <div className="desc">Désolé, cette page est introuvable</div>
          <div className="actions">
            {actions ||
              createElement(
                linkElement,
                {
                  to: redirect,
                  href: redirect,
                },
                <Button type="primary">{backText}</Button>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Exception;